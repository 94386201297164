<template>
    <Widget
        v-if="timetableState.selectedTimeSlot"
        class="widget"
    >
        <TimeSlotInput
            :model-value="timetableState.timetable[timetableState.selectedTimeSlot]"
            class="time-slot"
            :current-week="currentWeek"
            @update:modelValue="updateState"
        />
        <div
            class="action remove"
            tabindex="0"
            @click="remove"
        >
            <FontAwesomeIcon
                icon="trash"
                class="icon"
            />
        </div>
        <div
            :class="{updated: false}"
            class="action save"
            tabindex="0"
            @click="save"
        >
            <FontAwesomeIcon
                icon="save"
                class="icon"
            />
        </div>
    </Widget>
    <Widget
        v-else
        class="widget"
    >
        <TimeSlotInput
            v-model="timeSlot"
            :current-week="currentWeek"
            class="time-slot"
        />
        <div
            :class="{updated: isNotEmpty}"
            class="action add"
            tabindex="0"
            @click="submit"
        >
            <FontAwesomeIcon
                icon="calendar-plus"
                class="icon"
            />
        </div>
    </Widget>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { differenceInMinutes, getHours } from "date-fns";
import _ from "lodash";
import FontAwesomeIcon from "@/utils/fontawesome";
import TimeSlotInput from "@/components/timetable/TimeSlotInput.vue";
import Widget from "@/components/template/elements/Widget.vue";
import TimetableStore from "@/stores/timetable.store";
import { TimeSlot } from "@/services/timetable.service";

export default defineComponent({
    components: {
        FontAwesomeIcon,
        TimeSlotInput,
        Widget
    },
    props: {
        currentWeek: {
            type: Date as PropType<Date>,
            required: true
        }
    },
    data () {
        return {
            timeSlot: {} as TimeSlot,
            timetableState: TimetableStore.getState()
        };
    },
    computed: {
        isNotEmpty (): boolean {
            return (
                Number.isInteger(this.timeSlot.startDate) ||
                Number.isInteger(this.timeSlot.endDate));
        }
    },
    methods: {
        async save () {
            await TimetableStore.saveSlot(this.timetableState.selectedTimeSlot as string);
        },
        async submit () {
            await TimetableStore.createSlot(this.timeSlot);
            this.timeSlot = {} as TimeSlot;
        },
        async remove () {
            await TimetableStore.removeSlot(this.timetableState.selectedTimeSlot as string);
            TimetableStore.deselectTimeSlot();
        },
        async updateState (timeSlot: TimeSlot) {
            if (differenceInMinutes(timeSlot.endDate, timeSlot.startDate) < 30) {
                return;
            }

            if (getHours(timeSlot.startDate) < 7 || getHours(timeSlot.endDate) > 20) {
                return;
            }

            await TimetableStore.updateSlot({
                id: this.timetableState.selectedTimeSlot as string,
                ...timeSlot
            });
        }
    }
});
</script>

<style scoped lang="scss">
.widget {
    display: flex;
    overflow: hidden;
}

.time-slot {
    padding-top: 8px;
    padding-left: 32px;
}

.action {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: 80px;
    cursor: pointer;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;

    &.save {
        width: 0;
        overflow: hidden;
    }

    &.save,
    &.add {
        transition: .2s ease-in-out;
        background: var(--color-primary);

        &.updated {
            background: var(--color-secondary);
            width: 80px;

            .icon {
                animation: 0.5s ease-in-out 0s infinite alternate pulse;

                @keyframes pulse {
                    0% { transform: scale(1); }
                    50% { transform: scale(1); }
                    100% { transform: scale(1.1); }
                }
            }
        }

        .icon {
            color: var(--color-text-lightest);
        }
    }

    &.remove .icon {
        color: var(--color-text-light);
    }

    &:hover .icon {
        opacity: 1;
    }

    &:focus {
        border: 1px dotted var(--color-text-light);
        outline: none;
    }

    .icon {
        font-size: 32px;
        opacity: 0.7;
        transition: ease-in-out 0.1s;
    }
}
</style>
