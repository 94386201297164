<template>
    <div class="timetable-hour">
        <div class="title-spacer" />
        <div
            v-for="hour in hours"
            :key="hour.id"
            class="hour"
        >
            {{ hour.format }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

function generateHours (startHour: number, endHour: number): Array<{id: number; format: string}> {
    const hours: Array<{id: number; format: string}> = [];
    for (let hour = startHour; hour <= endHour; hour++) {
        hours.push({
            id: hour,
            format: `${hour.toString().padStart(2, "0")}:00`
        });
    }
    return hours;
}

export default defineComponent({
    props: {
        minHour: {
            type: Number,
            default: 7
        },
        maxHour: {
            type: Number,
            default: 20
        }
    },
    data () {
        return {
            hours: generateHours(this.minHour, this.maxHour)
        };
    }
});
</script>

<style scoped lang="scss">
.title-spacer {
    height: 32px;
}

.hour {
    padding-left: 16px;
    padding-top: 16px;
    border-bottom: 1px solid var(--color-shadow-soft);
    color: var(--color-text-light);
    font-size: 14px;
    height: 32px;
    box-sizing: border-box;
}
</style>
