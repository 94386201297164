
import { defineComponent } from "vue";
import { addDays, format as dateFormat, startOfDay, startOfWeek } from "date-fns";
import { fr as dateFr } from "date-fns/locale";
import * as TimeOptions from "./timeOptions";
import TimetableDay from "@/components/timetable/TimetableDay.vue";
import TimetableHours from "@/components/timetable/TimetableHours.vue";
import Widget from "@/components/template/elements/Widget.vue";
import FontAwesomeIcon from "@/utils/fontawesome";
import TimetableStore from "@/stores/timetable.store";
import SmallButton from "@/components/template/elements/SmallButton.vue";
import ContentSpacer from "@/components/template/ContentSpacer.vue";
import TimeSlotForm from "@/components/timetable/TimeSlotForm.vue";

const MIN_HOUR = 7;
const MAX_HOUR = 20;

export default defineComponent({
    components: {
        TimeSlotForm,
        ContentSpacer,
        SmallButton,
        FontAwesomeIcon,
        TimetableDay,
        TimetableHours,
        Widget
    },
    data () {
        return {
            daysOptions: TimeOptions.days,
            minHour: MIN_HOUR,
            maxHour: MAX_HOUR,
            startDay: startOfWeek(new Date(), { weekStartsOn: 1 })
        };
    },
    computed: {
        daysList (): Array<Date> {
            const list = [];
            for (let day = 0; day < 7; day++) {
                list.push(startOfDay(addDays(this.startDay, day)));
            }
            return list;
        },
        dayNumber (): string {
            return dateFormat(this.startDay, "d", { locale: dateFr });
        },
        month (): string {
            return dateFormat(this.startDay, "MMMM", { locale: dateFr });
        }
    },
    async mounted () {
        await TimetableStore.loadWeek(this.startDay);
    },
    methods: {
        deselectTimeSlot () {
            TimetableStore.deselectTimeSlot();
        },
        async previousWeek () {
            this.startDay = addDays(this.startDay, -7);
            await TimetableStore.loadWeek(this.startDay);
        },
        async nextWeek () {
            this.startDay = addDays(this.startDay, 7);
            await TimetableStore.loadWeek(this.startDay);
        },
        async copyPreviousWeek () {
            await TimetableStore.copyPreviousWeek(this.startDay);
            await TimetableStore.loadWeek(this.startDay);
        }
    }
});
