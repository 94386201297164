
import PanelLayout from "@/layouts/PanelLayout.vue";
import Timetable from "@/components/timetable/Timetable.vue";

export default {
    components: {
        PanelLayout,
        Timetable
    }
};
