import { differenceInMinutes, isEqual, isWithinInterval, startOfDay } from "date-fns";
import { TimeSlot } from "@/services/timetable.service";

function isBetween (date: Date, start: Date, end: Date): boolean {
    return isWithinInterval(date, { start, end });
}

export function doesIntersect (timeSlot1: TimeSlot, timeSlot2: TimeSlot): boolean {
    return isBetween(timeSlot1.startDate, timeSlot2.startDate, timeSlot2.endDate) ||
        isBetween(timeSlot1.endDate, timeSlot2.startDate, timeSlot2.endDate) ||
        isBetween(timeSlot2.startDate, timeSlot1.startDate, timeSlot1.endDate) ||
        isBetween(timeSlot2.endDate, timeSlot1.startDate, timeSlot1.endDate) ||
        isEqual(timeSlot1.startDate, timeSlot2.startDate) ||
        isEqual(timeSlot1.endDate, timeSlot2.endDate);
}

export function getElapsedMinutes (date: Date) {
    return date.getHours() * 60 + date.getMinutes();
}
