<template>
    <div class="time-slot-form">
        <FormSelect
            v-model="dayOfTheWeek"
            class="element"
            input-id="day-of-the-week"
            label="Jour"
            :options="daysOptions"
        />
        <FormSpacer class="spacer" />
        <FormInput
            v-model="startTime"
            class="element"
            input-id="start-time"
            label="Heure de début"
            type="time"
            min="07:00"
            max="20:00"
            step="300"
        />
        <FormSpacer class="spacer" />
        <FormInput
            v-model="endTime"
            class="element"
            input-id="end-time"
            label="Heure de fin"
            type="time"
            min="07:00"
            max="20:00"
            step="300"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { getDay, getHours, getMinutes, add as addDate, startOfWeek, differenceInMinutes } from "date-fns";
import * as TimeOptions from "./timeOptions";
import { days } from "./timeOptions";
import FormInput from "@/components/template/elements/FormInput.vue";
import FormSelect from "@/components/template/elements/FormSelect.vue";
import FormSpacer from "@/components/template/elements/FormSpacer.vue";
import { TimeSlot } from "@/services/timetable.service";

export default defineComponent({
    components: {
        FormInput,
        FormSelect,
        FormSpacer
    },
    props: {
        modelValue: {
            type: Object as PropType<TimeSlot>,
            default: () => {
                return {};
            }
        },
        currentWeek: {
            type: Date as PropType<Date>,
            required: true
        }
    },
    emits: ["update:modelValue"],
    data () {
        return {
            daysOptions: TimeOptions.days,
            dayOfTheWeek: undefined as undefined | number,
            startTime: undefined as undefined | string,
            endTime: undefined as undefined | string
        };
    },
    watch: {
        dayOfTheWeek () {
            this.emitUpdate();
        },
        startTime () {
            this.emitUpdate();
        },
        endTime () {
            this.emitUpdate();
        },
        modelValue: {
            immediate: true,
            handler () {
                if (this.modelValue.startDate) {
                    this.dayOfTheWeek = days[(getDay(this.modelValue.startDate) + 6) % 7].value;
                    this.startTime = this.invertFormatTime(this.modelValue.startDate);
                    this.endTime = this.invertFormatTime(this.modelValue.endDate);
                }
            }
        }
    },
    methods: {
        emitUpdate () {
            if (!this.dayOfTheWeek || !this.startTime || !this.endTime) {
                return;
            }

            const startDate = addDate(startOfWeek(this.currentWeek), this.formatTime(this.startTime));
            const endDate = addDate(startOfWeek(this.currentWeek), this.formatTime(this.endTime));

            if (differenceInMinutes(endDate, startDate) < 30) {
                return;
            }

            if (getHours(startDate) < 7 || getHours(endDate) > 20) {
                return;
            }

            if (this.modelValue.startDate && this.modelValue.endDate) {
                if ((this.modelValue.startDate.getTime() === startDate.getTime()) && (this.modelValue.endDate.getTime() === endDate.getTime())) {
                    return;
                }
            }

            this.$emit("update:modelValue", {
                ...this.modelValue,
                startDate,
                endDate
            });
        },
        formatTime (time: string): { days: number; hours: number; minutes: number } {
            const timeArray = time.split(":");
            const days = this.dayOfTheWeek as number;
            const hours = parseInt(timeArray[0]);
            const minutes = parseInt(timeArray[1]);
            return { days, hours, minutes };
        },
        invertFormatTime (date: Date): string {
            const hours = getHours(date);
            const minutes = getMinutes(date);
            return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
        }
    }
});
</script>

<style scoped lang="scss">
.time-slot-form {
    display: flex;
    width: 100%;
    font-size: 18px;

    .element {
        flex-basis: 120px;
    }

    .spacer {
        width: 32px;
    }
}
</style>
