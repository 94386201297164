
import { defineComponent, PropType } from "vue";
import { format as dateFormat } from "date-fns";
import { fr as dateFr } from "date-fns/locale";
import { TimeSlot } from "@/services/timetable.service";
import TimetableStore from "@/stores/timetable.store";
import FontAwesomeIcon from "@/utils/fontawesome";
import { getElapsedMinutes } from "@/components/timetable/utils";

export default defineComponent({
    components: {
        FontAwesomeIcon
    },
    props: {
        timeSlot: {
            type: Object as PropType<TimeSlot>,
            required: true
        },
        columns: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            timetableState: TimetableStore.getState()
        };
    },
    computed: {
        isSelected (): boolean {
            return this.timetableState.selectedTimeSlot === this.timeSlot.id;
        },
        getStyle (): Record<string, string> {
            const wrapperPadding = 8;
            const columnTotal = this.columns.length;
            const columnNumber = this.timeSlot.column as number;

            const leftOffsetPercentage = columnNumber * 100 / columnTotal;
            const rightOffsetPercentage = (columnTotal - columnNumber - 1) * 100 / columnTotal;

            const left = `calc( ${leftOffsetPercentage}% + ${wrapperPadding}px )`;
            const right = `calc( ${rightOffsetPercentage}% + ${wrapperPadding}px )`;

            return {
                top: `${(getElapsedMinutes(this.timeSlot.startDate) / 60 - 7) * 32 + 64}px`,
                height: `${(getElapsedMinutes(this.timeSlot.endDate) - getElapsedMinutes(this.timeSlot.startDate)) / 60 * 32}px`,
                right,
                left
            };
        }
    },
    methods: {
        selectTimeSlot (event: Event) {
            event.stopPropagation();
            TimetableStore.selectTimeSlot(this.timeSlot.id as string);
        },
        formatHour (date: Date): string {
            return dateFormat(date, "H:mm", { locale: dateFr });
        },
        remove () {
            TimetableStore.removeSlot(this.timeSlot.id as string);
        }
    }
});
