
import { defineComponent, PropType } from "vue";
import { differenceInMinutes, getHours } from "date-fns";
import _ from "lodash";
import FontAwesomeIcon from "@/utils/fontawesome";
import TimeSlotInput from "@/components/timetable/TimeSlotInput.vue";
import Widget from "@/components/template/elements/Widget.vue";
import TimetableStore from "@/stores/timetable.store";
import { TimeSlot } from "@/services/timetable.service";

export default defineComponent({
    components: {
        FontAwesomeIcon,
        TimeSlotInput,
        Widget
    },
    props: {
        currentWeek: {
            type: Date as PropType<Date>,
            required: true
        }
    },
    data () {
        return {
            timeSlot: {} as TimeSlot,
            timetableState: TimetableStore.getState()
        };
    },
    computed: {
        isNotEmpty (): boolean {
            return (
                Number.isInteger(this.timeSlot.startDate) ||
                Number.isInteger(this.timeSlot.endDate));
        }
    },
    methods: {
        async save () {
            await TimetableStore.saveSlot(this.timetableState.selectedTimeSlot as string);
        },
        async submit () {
            await TimetableStore.createSlot(this.timeSlot);
            this.timeSlot = {} as TimeSlot;
        },
        async remove () {
            await TimetableStore.removeSlot(this.timetableState.selectedTimeSlot as string);
            TimetableStore.deselectTimeSlot();
        },
        async updateState (timeSlot: TimeSlot) {
            if (differenceInMinutes(timeSlot.endDate, timeSlot.startDate) < 30) {
                return;
            }

            if (getHours(timeSlot.startDate) < 7 || getHours(timeSlot.endDate) > 20) {
                return;
            }

            await TimetableStore.updateSlot({
                id: this.timetableState.selectedTimeSlot as string,
                ...timeSlot
            });
        }
    }
});
