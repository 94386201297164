<template>
    <PanelLayout title="Emploi du temps">
        <Timetable />
    </PanelLayout>
</template>

<script lang="ts">
import PanelLayout from "@/layouts/PanelLayout.vue";
import Timetable from "@/components/timetable/Timetable.vue";

export default {
    components: {
        PanelLayout,
        Timetable
    }
};
</script>

<style scoped lang="scss">

</style>
