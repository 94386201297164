<template>
    <div
        ref="day"
        class="timetable-day"
        :class="{pointer:isCreationDisplay && !isSelected}"
        @onmouseenter="displaySlotCreation"
        @mousemove="displaySlotCreation"
        @mouseleave="hideSlotCreation"
        @mousedown="createSlot"
        @mouseup="saveGrabbing"
    >
        <h2>{{ dayName }} <span class="date">{{ dayNumber }}</span></h2>
        <div
            v-if="isGrabbing"
            class="preview-time-slot"
            :style="getPreviewStyle"
        />
        <transition-group
            name="slot"
            tag="div"
        >
            <TimetableTimeSlot
                v-for="timeSlot in timeSlots"
                :key="timeSlot.id"
                :time-slot="timeSlot"
                :columns="columns"
            >
                <div
                    class="upper-grab"
                    @mousedown="startGrab($event, timeSlot.id, true)"
                />
                <div
                    class="lower-grab"
                    @mousedown="startGrab($event, timeSlot.id, false)"
                />
            </TimetableTimeSlot>
        </transition-group>
        <div
            v-if="isCreationDisplay && !isSelected"
            class="slot-creation"
            :style="getCreationStyle"
        >
            <FontAwesomeIcon icon="plus" />
        </div>
        <div
            class="move-graph-section"
            :class="{display:isGrabbing}"
            @mousemove="throttledMoveGrab"
            @mouseleave="stopGrabbing"
        >
            <div
                class="grab"
                :style="getGrabStyle"
            >
                <div class="hour">
                    {{ formatHour(grabTime) }}
                </div>
            </div>
        </div>
        <div
            v-if="displayCurrentTime"
            class="current-time"
            :style="getCurrentTimeStyle"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import _ from "lodash";
import { addMinutes, differenceInMinutes, format as dateFormat, isSameDay, startOfDay } from "date-fns";
import { fr as dateFr } from "date-fns/locale";
import FontAwesomeIcon from "@/utils/fontawesome";
import { TimeSlot } from "@/services/timetable.service";
import TimetableStore from "@/stores/timetable.store";
import TimetableTimeSlot from "@/components/timetable/TimetableTimeSlot.vue";
import { doesIntersect, getElapsedMinutes } from "@/components/timetable/utils";

export default defineComponent({
    components: {
        FontAwesomeIcon,
        TimetableTimeSlot
    },
    props: {
        date: {
            type: Object as PropType<Date>,
            required: true
        }
    },
    emits: ["click"],
    data () {
        return {
            timetableState: TimetableStore.getState(),
            timeSlots: [] as Array<TimeSlot>,
            columns: [] as Array<Array<string>>,
            isCreationDisplay: false,
            creationTime: 0,
            grabTime: 0,
            isGrabbing: false,
            isUpperGrab: false,
            timeSlotGrabbed: "" as string,
            grabOffset: 0,
            throttledMoveGrab: undefined as any
        };
    },
    computed: {
        dayName (): string {
            return dateFormat(this.date, "EEEE", { locale: dateFr });
        },
        dayNumber (): string {
            return dateFormat(this.date, "d", { locale: dateFr });
        },
        getCreationStyle (): Record<string, string> {
            return {
                top: `${(this.creationTime / 60 - 7) * 32 + 64}px`
            };
        },
        getGrabStyle (): Record<string, string> {
            return {
                top: `${(this.grabTime / 60 - 5) * 32 - 4}px`
            };
        },
        getPreviewStyle (): Record<string, string> {
            if (this.isUpperGrab) {
                const elapsedTime = getElapsedMinutes(this.timetableState.timetable[this.timeSlotGrabbed].endDate) - this.grabTime;
                return {
                    top: `${(this.grabTime / 60 - 7) * 32 + 64}px`,
                    height: `${(elapsedTime / 60 - 2) * 32 + 64}px`
                };
            }
            else {
                const elapsedTime = this.grabTime - getElapsedMinutes(this.timetableState.timetable[this.timeSlotGrabbed].startDate);
                return {
                    top: `${(getElapsedMinutes(this.timetableState.timetable[this.timeSlotGrabbed].startDate) / 60 - 7) * 32 + 64}px`,
                    height: `${(elapsedTime / 60 - 2) * 32 + 64}px`
                };
            }
        },
        getCurrentTimeStyle (): Record<string, string> {
            const now = new Date();
            let minutes = differenceInMinutes(now, startOfDay(now));
            minutes = _.clamp(minutes, 7 * 60, 20 * 60);
            return {
                top: `${(minutes / 60 - 7) * 32 + 64}px`
            };
        },
        isSelected (): boolean {
            return !!this.timetableState.selectedTimeSlot;
        },
        displayCurrentTime (): boolean {
            return isSameDay(new Date(), this.date);
        }
    },
    watch: {
        "timetableState.timetable": {
            immediate: true,
            deep: true,
            handler () {
                this.updateTimetable();
            }
        }
    },
    mounted () {
        this.throttledMoveGrab = _.throttle(this.moveGrab, 1000 / 60);
    },
    methods: {
        formatHour (time: number): string {
            const hours = Math.floor(time / 60);
            const minutes = time - hours * 60;
            return `${hours.toString()}:${minutes.toString().padStart(2, "0")}`;
        },
        moveGrab (event: MouseEvent) {
            if (this.isGrabbing && event.offsetY) {
                this.grabOffset = event.offsetY;
                this.grabTime = (Math.floor((this.grabOffset + 8) / 16) / 2 + 5) * 60;
                const time = (this.grabOffset / 32 + 5) * 60;
                if (time < 7 * 60 || time > 20 * 60) {
                    this.isGrabbing = false;
                }
            }
        },
        startGrab (event: MouseEvent, timeSlotId: string, isUpperGrab: boolean) {
            const grabPosition = (event.target as HTMLElement).getBoundingClientRect().top;
            const dayPosition = (this.$refs.day as HTMLElement).getBoundingClientRect().top;
            this.grabOffset = grabPosition - dayPosition;
            this.grabTime = (Math.floor((this.grabOffset + 8) / 16) / 2 + 5) * 60;
            this.timeSlotGrabbed = timeSlotId;
            this.isGrabbing = true;
            this.isUpperGrab = isUpperGrab;
        },
        stopGrabbing () {
            this.isGrabbing = false;
        },
        async saveGrabbing () {
            this.isGrabbing = false;
            if (!this.timeSlotGrabbed) {
                return;
            }

            if (this.isUpperGrab) {
                if (getElapsedMinutes(this.timetableState.timetable[this.timeSlotGrabbed].endDate) - this.grabTime < 30) {
                    return;
                }
                await TimetableStore.updateSlot({
                    id: this.timeSlotGrabbed,
                    startDate: addMinutes(this.date, this.grabTime)
                });
            }
            else {
                if (this.grabTime - getElapsedMinutes(this.timetableState.timetable[this.timeSlotGrabbed].startDate) < 30) {
                    return;
                }
                await TimetableStore.updateSlot({
                    id: this.timeSlotGrabbed,
                    endDate: addMinutes(this.date, this.grabTime)
                });
            }
        },
        displaySlotCreation (event: MouseEvent) {
            const className = (event.target as HTMLElement).className;
            if (!className || typeof className !== "string") {
                return;
            }
            const isEmptySpot = className.split(" ").includes("timetable-day");
            this.creationTime = (Math.floor((event.offsetY - 8) / 16) / 2 + 5) * 60;
            this.isCreationDisplay = isEmptySpot && (this.creationTime >= 7 * 60) && (this.creationTime <= 19 * 60);
        },
        hideSlotCreation () {
            this.isCreationDisplay = false;
        },
        async createSlot (event: MouseEvent) {
            if (this.isCreationDisplay && !this.isSelected && event.button === 0) {
                event.preventDefault();
                await TimetableStore.createSlot({
                    startDate: addMinutes(this.date, this.creationTime),
                    endDate: addMinutes(this.date, this.creationTime + 60)
                });
            }
        },
        updateTimetable () {
            this.timeSlots = [];
            this.columns = [];

            if (!this.timetableState.timetable) {
                return;
            }

            for (const timeslotId of Object.keys(this.timetableState.timetable)) {
                if (isSameDay(this.timetableState.timetable[timeslotId].startDate, this.date)) {
                    this.timeSlots.push({
                        id: timeslotId,
                        ...this.timetableState.timetable[timeslotId]
                    });
                }
            }

            this.computeIntersections();
        },
        computeIntersections () {
            for (const timeSlot of this.timeSlots) {
                let columnNumber = 0;
                let insertionFlag = false;
                while (!insertionFlag) {
                    insertionFlag = true;
                    if (!this.columns[columnNumber]) {
                        break;
                    }
                    for (const timeSlotId of this.columns[columnNumber]) {
                        if (doesIntersect(timeSlot, this.timetableState.timetable[timeSlotId])) {
                            columnNumber++;
                            insertionFlag = false;
                            break;
                        }
                    }
                }
                if (!this.columns[columnNumber]) {
                    this.columns[columnNumber] = [];
                }
                timeSlot.column = columnNumber;
                this.columns[columnNumber].push(timeSlot.id as string);
            }
        }
    }
});
</script>

<style scoped lang="scss">
.timetable-day {
    position: relative;
    height: 100%;

    &.pointer {
        cursor: pointer;
    }

    h2 {
        padding-top: 16px;
        margin: 0;
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        text-transform: capitalize;

        .date {
            color: var(--color-primary);
        }
    }

    .grab {
        position: relative;
        pointer-events: none;

        .hour {
            position: absolute;
            top: -7px;
            left: calc(50% - 24px);
            width: 48px;
            height: 22px;
            color: var(--color-text-lightest);
            text-align: center;
            pointer-events: none;
            background: var(--color-text);
            border-radius: 8px;
        }
    }

    .upper-grab,
    .lower-grab,
    .grab {
        position: absolute;
        right: 0;
        left: 0;
        height: 8px;
        cursor: row-resize;
        background: var(--color-text);
        z-index: 100;
    }

    .upper-grab,
    .lower-grab {
        opacity: 0;
    }

    .upper-grab:hover,
    .lower-grab:hover {
        cursor: row-resize;
        opacity: 1;
    }

    .upper-grab {
        top: -4px;
    }

    .lower-grab {
        bottom: -4px;
    }

    .preview-time-slot {
        position: absolute;
        right: 0;
        left: 0;
        background: var(--color-shadow-soft);
    }
}

.move-graph-section {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: none;
    cursor: row-resize;

    &.display {
        display: block;
    }
}

.slot-creation {
    position: absolute;
    right: 8px;
    left: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 20px;
    color: var(--color-primary);
    pointer-events: none;
    background: transparent;
    border: 3px solid var(--color-primary);
    border-radius: 16px;
}

.slot-enter-active,
.slot-leave-active {
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.slot-enter-from,
.slot-leave-to {
    opacity: 0;
}

.current-time {
    position: absolute;
    height: 4px;
    background: var(--color-primary);
    pointer-events: none;
    right: 0;
    left: 0;
}
</style>
