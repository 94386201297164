<template>
    <div
        class="time-slot"
        :class="{isSelected, isUpdated:timeSlot.updated}"
        :style="getStyle"
        @click="selectTimeSlot"
    >
        <div class="start-time">
            {{ formatHour(timeSlot.startDate) }}
        </div>
        <div class="end-time">
            {{ formatHour(timeSlot.endDate) }}
        </div>
        <slot />
        <div
            v-if="isSelected"
            class="delete"
            @click="remove"
        >
            <FontAwesomeIcon
                icon="trash"
                class="icon"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { format as dateFormat } from "date-fns";
import { fr as dateFr } from "date-fns/locale";
import { TimeSlot } from "@/services/timetable.service";
import TimetableStore from "@/stores/timetable.store";
import FontAwesomeIcon from "@/utils/fontawesome";
import { getElapsedMinutes } from "@/components/timetable/utils";

export default defineComponent({
    components: {
        FontAwesomeIcon
    },
    props: {
        timeSlot: {
            type: Object as PropType<TimeSlot>,
            required: true
        },
        columns: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            timetableState: TimetableStore.getState()
        };
    },
    computed: {
        isSelected (): boolean {
            return this.timetableState.selectedTimeSlot === this.timeSlot.id;
        },
        getStyle (): Record<string, string> {
            const wrapperPadding = 8;
            const columnTotal = this.columns.length;
            const columnNumber = this.timeSlot.column as number;

            const leftOffsetPercentage = columnNumber * 100 / columnTotal;
            const rightOffsetPercentage = (columnTotal - columnNumber - 1) * 100 / columnTotal;

            const left = `calc( ${leftOffsetPercentage}% + ${wrapperPadding}px )`;
            const right = `calc( ${rightOffsetPercentage}% + ${wrapperPadding}px )`;

            return {
                top: `${(getElapsedMinutes(this.timeSlot.startDate) / 60 - 7) * 32 + 64}px`,
                height: `${(getElapsedMinutes(this.timeSlot.endDate) - getElapsedMinutes(this.timeSlot.startDate)) / 60 * 32}px`,
                right,
                left
            };
        }
    },
    methods: {
        selectTimeSlot (event: Event) {
            event.stopPropagation();
            TimetableStore.selectTimeSlot(this.timeSlot.id as string);
        },
        formatHour (date: Date): string {
            return dateFormat(date, "H:mm", { locale: dateFr });
        },
        remove () {
            TimetableStore.removeSlot(this.timeSlot.id as string);
        }
    }
});
</script>

<style scoped lang="scss">
.time-slot {
    position: absolute;
    box-sizing: border-box;
    cursor: pointer;
    background: var(--color-primary);
    border-radius: 16px;

    &.isSelected {
        z-index: 3;
        border: 3px dashed var(--color-text);
    }

    &.isUpdated {
        background: var(--color-text-light);
    }

    .start-time,
    .end-time {
        pointer-events: none;
        position: absolute;
        z-index: 6;
        font-size: 12px;
        font-weight: 600;
        color: var(--color-text-lightest);
    }

    .start-time {
        top: -2px;
        left: 16px;
    }

    .end-time {
        right: 16px;
        bottom: 0;
    }

    .delete {
        position: absolute;
        top: -16px;
        right: -16px;
        width: 32px;
        z-index: 200;
        height: 32px;
        background: var(--color-text);
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            color: var(--color-text-lightest);
            opacity: .5;
            transition: .2s ease-in-out;
        }

        &:hover {
            .icon {
                opacity: 1;
            }
        }
    }
}
</style>
