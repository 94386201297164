import { isEqual as dateIsEqual } from "date-fns";
import { Store } from "./model";

import TimetableService, { Timetable } from "@/services/timetable.service";

interface TimetableStoreData extends Record<string, unknown> {
    loaded: boolean;
    selectedTimeSlot?: string;
    timetable: Timetable;
}

class TimetableStore extends Store<TimetableStoreData> {
    protected data (): TimetableStoreData {
        return {
            loaded: false,
            timetable: {}
        };
    }

    async createSlot ({ startDate, endDate }: { startDate: Date; endDate: Date }): Promise<void> {
        const timeSlot = await TimetableService.createSlot({ startDate, endDate });
        this.state.timetable[timeSlot.id as string] = timeSlot;
        this.state.selectedTimeSlot = timeSlot.id as string;
    }

    async saveSlot (slotId: string): Promise<void> {
        const timeSlot = this.state.timetable[slotId];

        if (!this.state.timetable[timeSlot.id as string].updated) {
            return;
        }

        await TimetableService.updateSlot(timeSlot);
        this.state.timetable[timeSlot.id as string].updated = false;
    }

    async removeSlot (slotId: string): Promise<void> {
        const timeSlot = this.state.timetable[slotId];

        await TimetableService.removeSlot(timeSlot);

        delete this.state.timetable[slotId];
    }

    async updateSlot ({ id, startDate, endDate }: { id: string; startDate?: Date; endDate?: Date }): Promise<void> {
        if (!this.state.timetable?.[id]) {
            return;
        }

        if (startDate !== undefined && !dateIsEqual(this.state.timetable[id].startDate, startDate)) {
            this.state.timetable[id].startDate = startDate;
            this.state.timetable[id].updated = true;
        }

        if (endDate !== undefined && !dateIsEqual(this.state.timetable[id].endDate, endDate)) {
            this.state.timetable[id].endDate = endDate;
            this.state.timetable[id].updated = true;
        }

        await this.saveSlot(id);
    }

    async loadWeek (date: Date): Promise<void> {
        this.state.timetable = await TimetableService.loadWeek(date);
        this.state.loaded = true;
    }

    selectTimeSlot (timeSlotId: string): void {
        this.state.selectedTimeSlot = timeSlotId;
    }

    deselectTimeSlot (): void {
        delete this.state.selectedTimeSlot;
    }

    async copyPreviousWeek (date: Date): Promise<void> {
        await TimetableService.copyPreviousWeek(date);
    }
}

const timetableStore = new TimetableStore();
export default timetableStore as TimetableStore;
