import ApiService from "./api.service";

export interface TimeSlot {
    id?: string;
    startDate: Date;
    endDate: Date;
    updated?: boolean;
    column?: number;
}

export interface Timetable {
    [index: string]: TimeSlot;
}

const TimetableService = {
    async loadWeek (date: Date): Promise<Timetable> {
        const timetable = await ApiService.post("/timetable/week", {
            date
        }) as Timetable;

        for (const timeSlotId of Object.keys(timetable)) {
            timetable[timeSlotId].startDate = new Date(timetable[timeSlotId].startDate);
            timetable[timeSlotId].endDate = new Date(timetable[timeSlotId].endDate);
        }

        return timetable;
    },

    async createSlot ({ startDate, endDate }: { startDate: Date; endDate: Date }): Promise<TimeSlot> {
        const timeSlot = await ApiService.post("/timetable/slot/create", {
            startDate,
            endDate
        }) as TimeSlot;

        timeSlot.startDate = new Date(timeSlot.startDate);
        timeSlot.endDate = new Date(timeSlot.endDate);

        return timeSlot;
    },

    async updateSlot (timeSlot: TimeSlot): Promise<void> {
        await ApiService.put("/timetable/slot/update", {
            slotId: timeSlot.id,
            startDate: timeSlot.startDate,
            endDate: timeSlot.endDate
        });
    },

    async removeSlot (timeSlot: TimeSlot): Promise<void> {
        await ApiService.delete("/timetable/slot/remove", {
            slotId: timeSlot.id
        });
    },

    async copyPreviousWeek (date: Date): Promise<void> {
        await ApiService.post("/timetable/copyPreviousWeek", {
            date
        });
    }
};

export default TimetableService;
