<template>
    <div class="timetable-view">
        <Widget>
            <TimeSlotForm :current-week="startDay" />
        </Widget>
        <ContentSpacer size="xl" />
        <Widget
            class="widget"
            @click="deselectTimeSlot"
        >
            <div class="week-navigation">
                <FontAwesomeIcon
                    icon="angle-double-left"
                    class="navigation"
                    @click="previousWeek"
                />
                <div class="week">
                    Semaine du <span class="date">{{ dayNumber }}</span> <span class="month">{{ month }}</span>
                </div>
                <FontAwesomeIcon
                    icon="angle-double-right"
                    class="navigation"
                    @click="nextWeek"
                />
            </div>
            <div class="timetable-wrapper">
                <TimetableHours
                    class="background"
                    :min-hour="minHour"
                    :max-hour="maxHour"
                />
                <div class="timetable">
                    <div class="hour-spacer" />
                    <TimetableDay
                        v-for="day in daysList"
                        :key="day"
                        :date="day"
                        class="day"
                    />
                </div>
            </div>
            <SmallButton
                class="button"
                @click="copyPreviousWeek"
            >
                Copier la semaine précédente
            </SmallButton>
        </Widget>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { addDays, format as dateFormat, startOfDay, startOfWeek } from "date-fns";
import { fr as dateFr } from "date-fns/locale";
import * as TimeOptions from "./timeOptions";
import TimetableDay from "@/components/timetable/TimetableDay.vue";
import TimetableHours from "@/components/timetable/TimetableHours.vue";
import Widget from "@/components/template/elements/Widget.vue";
import FontAwesomeIcon from "@/utils/fontawesome";
import TimetableStore from "@/stores/timetable.store";
import SmallButton from "@/components/template/elements/SmallButton.vue";
import ContentSpacer from "@/components/template/ContentSpacer.vue";
import TimeSlotForm from "@/components/timetable/TimeSlotForm.vue";

const MIN_HOUR = 7;
const MAX_HOUR = 20;

export default defineComponent({
    components: {
        TimeSlotForm,
        ContentSpacer,
        SmallButton,
        FontAwesomeIcon,
        TimetableDay,
        TimetableHours,
        Widget
    },
    data () {
        return {
            daysOptions: TimeOptions.days,
            minHour: MIN_HOUR,
            maxHour: MAX_HOUR,
            startDay: startOfWeek(new Date(), { weekStartsOn: 1 })
        };
    },
    computed: {
        daysList (): Array<Date> {
            const list = [];
            for (let day = 0; day < 7; day++) {
                list.push(startOfDay(addDays(this.startDay, day)));
            }
            return list;
        },
        dayNumber (): string {
            return dateFormat(this.startDay, "d", { locale: dateFr });
        },
        month (): string {
            return dateFormat(this.startDay, "MMMM", { locale: dateFr });
        }
    },
    async mounted () {
        await TimetableStore.loadWeek(this.startDay);
    },
    methods: {
        deselectTimeSlot () {
            TimetableStore.deselectTimeSlot();
        },
        async previousWeek () {
            this.startDay = addDays(this.startDay, -7);
            await TimetableStore.loadWeek(this.startDay);
        },
        async nextWeek () {
            this.startDay = addDays(this.startDay, 7);
            await TimetableStore.loadWeek(this.startDay);
        },
        async copyPreviousWeek () {
            await TimetableStore.copyPreviousWeek(this.startDay);
            await TimetableStore.loadWeek(this.startDay);
        }
    }
});
</script>

<style scoped lang="scss">
.week-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin-top: 16px;

    .week {
        font-size: 24px;
        font-weight: 100;
        width:280px;
        text-align: center;

        .date {
            color: var(--color-primary);
        }

        .month {
            text-transform: capitalize;
        }
    }

    .navigation {
        font-size: 32px;
        color: var(--color-text-light);
        cursor: pointer;
        user-select: none;
        opacity: 0.5;
        transition: 0.2s ease-in-out;
        padding: 0 16px;

        &:hover {
            color: var(--color-primary);
            opacity: 1;
        }
    }
}

.widget {
    overflow: hidden;
    position: relative;
}

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.timetable-wrapper {
    position: relative;
    height: (20 - 7 + 1) * 32px + 32px;
}

.timetable {
    position: absolute;
    z-index: 1;
    padding: 0 16px;
    display: flex;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    .hour-spacer {
        width: 48px;
    }

    .day {
        flex: 1 1 0;
    }
}

.button {
    margin: 16px;
}
</style>
