
import { defineComponent } from "vue";

function generateHours (startHour: number, endHour: number): Array<{id: number; format: string}> {
    const hours: Array<{id: number; format: string}> = [];
    for (let hour = startHour; hour <= endHour; hour++) {
        hours.push({
            id: hour,
            format: `${hour.toString().padStart(2, "0")}:00`
        });
    }
    return hours;
}

export default defineComponent({
    props: {
        minHour: {
            type: Number,
            default: 7
        },
        maxHour: {
            type: Number,
            default: 20
        }
    },
    data () {
        return {
            hours: generateHours(this.minHour, this.maxHour)
        };
    }
});
